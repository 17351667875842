import React, { useEffect } from "react"
import Layout from "../Components/layout"
import SEO from "../Components/SEO"
import HpBanner from "../constants/HpBanner"
import { useStaticQuery, graphql } from "gatsby"

// let test = `
//     <script type="text/javascript">FlixjQ("#flixsis").SIS({descriptor: 'OTI4JjE3MDc5JmFwal9lbg==' });</script>
//     `

const Hp = props => {
  const Image = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "hp/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS9nYS9lbiZtPUdBJmNjPXBoJnA9Mi1TSVVELTI0MjU5"
    script.async = true
    document.getElementById("hpDiv").appendChild(script)
    return () => {
      document.getElementById("hpDiv").removeChild(script)
    }
  }, [])

  return (
    <Layout>
      <SEO title="HP Catalogue" description="Fastlink HP Catalogue"></SEO>
      <HpBanner image={Image.banner.childImageSharp.fluid} />
      <div id="hpDiv"></div>
      {/* <script type="text/javascript" src="//media.flixsyndication.net/js/v2/flixmedia.sis.https.js"></script>
            <div id="flixsis"></div>
            <script dangerouslySetInnerHTML={{
                __html: ` 
                    FlixjQ("#flixsis").SIS({descriptor: 'OTI4JjE3MDc5JmFwal9lbg==' });`}} /> */}
      {/* <script
        type="text/javascript"
        src="//syndication.inc.hp.com/inpage/contentid.min.js#Yz1zaG93Y2FzZS9nYS9lbiZtPUdBJmNjPXBoJnA9Mi1TSVVELTI0MjU5"
      ></script> */}
    </Layout>
  )
}

export default Hp
