import React from "react"
import Img from "gatsby-image"

import { Link } from "gatsby"

import style from "./styles.module.css"

const HpBanner = ({ image, title }) => {
    return (
        <div className={style.container}>
            <Img
                Tag="section"
                className={style.banner}
                fluid={image}
            />
            <Link to="/contact" className={style.button}>Buy Now</Link>
        </div>


    )
}

export default HpBanner
